/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$CareTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$EnterpriseID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$IPID.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$StaffID.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XePatientProviderType.json';
import schema_5 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XeUserData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery.json#"},"Active":{"type":"boolean"},"CareTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$CareTypeID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$EnterpriseID.json#"}]},"IPID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$IPID.json#"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PhoneList":{"type":"string"},"ProviderID":{"type":"integer","primaryKey":"generated"},"ProviderTypeID":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"RelationFlagBits":{"defaultValue":0,"maximum":9007199254740991,"type":"integer"},"RestrictValue":{"maximum":99999,"type":"integer"},"StaffID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$StaffID.json#"},"StaffID_EnterpriseID":{"type":"string"},"XePatientProviderType":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XePatientProviderType.json#"},"XeUserData":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XeUserData.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
