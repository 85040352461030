/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$ContactInfoTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$EnterpriseID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$XePatientAddress.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"AddressID":{"type":"string","primaryKey":"generated","maxLength":32},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"AddressLine3":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"ContactInfoTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$ContactInfoTypeID.json#"},"CountryID":{"type":"string","maxLength":16},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Email":{"type":"string","maxLength":60},"EndDate":{"format":"date","nullable":true,"type":"string"},"EnterpriseID":{"oneOf":[{"nullable":true,"type":"string"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$EnterpriseID.json#"}]},"Fax":{"type":"string","maxLength":30},"IPID":{"type":"integer","primaryKey":"supplied"},"IsHomeless":{"type":"boolean"},"IsPreferred":{"type":"boolean"},"IsPrescribing":{"type":"boolean"},"MobilePhone":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Notes":{"type":"string","maxLength":500},"Phone":{"type":"string","maxLength":30},"PreferredMethod":{"type":"string","maxLength":16},"StartDate":{"format":"date","nullable":true,"type":"string"},"StateProvince":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$XePatientAddress.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$XePatientAddress.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
