/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XeUserData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientPortal.json#"},"AllowedProxyUsers":{"type":"string","maxLength":100},"IPID":{"type":"integer","primaryKey":"supplied"},"PersonID":{"type":"string","maxLength":128},"Username":{"type":"string"},"XeUserData":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XeUserData.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientPortal.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientPortal.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
