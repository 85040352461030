/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json#"},"CatalogID":{"type":"integer","primaryKey":"supplied"},"DiagnosisID":{"type":"string","primaryKey":"supplied","maxLength":32},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
