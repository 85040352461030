/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$HealthPlanID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientPlan.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"ExpireDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"ExpireDateTimeOff":{"type":"string"},"GroupID":{"type":"string","maxLength":60},"HICN":{"type":"string","maxLength":20},"HICNSearchGroup":{"type":"string"},"HealthPlanID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$HealthPlanID.json#"},"MemberID":{"type":"string","maxLength":60},"MemberIDSearchGroup":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PatientPlanID":{"type":"string","primaryKey":"generated","maxLength":32},"PlanID":{"type":"string","maxLength":60},"QuerySort":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientPlan.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientPlan.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
