/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProviderRequest.json#"},"Active":{"type":"boolean"},"ActiveXeValue":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EndDate":{"format":"date-time","nullable":true,"type":"string"},"IVID":{"items":{"type":"integer"},"type":"array"},"IsOnline":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ProviderTypeID":{"type":"string","primaryKey":"supplied","maxLength":30},"StaffID":{"type":"integer"},"StaffID_EnterpriseID":{"type":"string"},"StartDate":{"format":"date-time","nullable":true,"type":"string"},"TimeSheetID":{"type":"integer"},"VisitProviderID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProviderRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProviderRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
