/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeAuth.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XePatientIssueVisit.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"Comments":{"type":"string","maxLength":1024},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IVID":{"type":"integer","primaryKey":"supplied"},"IssueIVID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"XeAuth":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeAuth.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XePatientIssueVisit.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XePatientIssueVisit.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
