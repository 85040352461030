/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddVisitRequest.json#"},"Active":{"type":"boolean"},"CareTypeID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EndDate":{"format":"date-time","nullable":true,"type":"string"},"IVID":{"type":"string"},"IsUniqueByProviderTypeID":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ProviderTypeID":{"type":"string","maxLength":30},"RelationFlagBits":{"defaultValue":0,"maximum":9007199254740991,"type":"integer"},"StaffID":{"type":"integer"},"StaffID_EnterpriseID":{"type":"string"},"StartDate":{"format":"date-time","nullable":true,"type":"string"},"VisitProviderID":{"type":"string"}},"required":["IVID","ProviderTypeID","StaffID"],"$id":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddVisitRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddVisitRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
