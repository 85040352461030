/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitWithProvider$DiagnosisID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitWithProvider$XeVisitDiagnosis.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DiagnosisID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitWithProvider$DiagnosisID.json#"},"IsFinal":{"type":"boolean"},"IsPrimaryDiagnosis":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"RestrictValue":{"maximum":99999,"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitWithProvider$XeVisitDiagnosis.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitWithProvider$XeVisitDiagnosis.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
