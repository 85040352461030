/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitDiagnosisOrderBase$XeOrderCodes.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitDiagnosisOrderBase$XeOrderBase.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DerivedDescription":{"type":"string"},"DerivedMedicationType":{"type":"string"},"DerivedOrderType":{"type":"string"},"Description":{"type":"string","maxLength":500},"IVID":{"type":"integer"},"IsInError":{"type":"boolean"},"IsInErrorFilter":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string"},"OrderID":{"type":"integer","primaryKey":"generated"},"RestrictValue":{"maximum":99999,"type":"integer"},"RightsBase":{"type":"string","maxLength":32},"ServiceID":{"type":"integer"},"ServiceSubTypeID":{"type":"string","maxLength":32},"ServiceTypeID":{"type":"string","maxLength":32},"VisitAssessmentID":{"type":"string"},"VisitDiagnosisID":{"type":"integer"},"XeOrderCodes":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitDiagnosisOrderBase$XeOrderCodes.json#"},"type":"array"}},"required":["ServiceTypeID"],"$id":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitDiagnosisOrderBase$XeOrderBase.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitDiagnosisOrderBase$XeOrderBase.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
