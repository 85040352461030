/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest$XeRefCatModifier.json#"},"Active":{"type":"string","maxLength":1},"ActiveXeValue":{"type":"string"},"CatItemID":{"type":"integer"},"CodeRecordID":{"type":"string","maxLength":32},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest$XeRefCatModifier.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest$XeRefCatModifier.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
