/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance$EnterpriseID.json#"},"Abbr":{"type":"string"},"EnterpriseID":{"type":"string"},"EnterpriseName":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance$EnterpriseID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance$EnterpriseID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
