/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived$XeRefCatModifier.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.XeServiceCodeCatalog.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveXeValue":{"type":"string"},"CatItemID":{"type":"integer","primaryKey":"supplied"},"CatalogID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsMultUnits":{"type":"boolean"},"IsMultUnitsXeValue":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":500},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"},"XeRefCatModifier":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived$XeRefCatModifier.json#"},"type":"array"},"XeServiceCodeCatalog":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.XeServiceCodeCatalog.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
