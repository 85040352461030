/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit$XeScanDoc.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit$XeScanDocSet.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit.json#"},"CategoryID":{"type":"string","maxLength":30},"Comments":{"type":"string","maxLength":1000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DocumentTypeID":{"type":"string","maxLength":30},"DocumentTypeIDOperator":{"type":"string"},"EndPage":{"maximum":9999,"type":"integer"},"FileID":{"type":"integer"},"FileName":{"type":"string","maxLength":200},"ItemID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ParentStatusCode":{"type":"string","maxLength":30},"ScanDocID":{"type":"integer","primaryKey":"generated"},"SourceID":{"type":"string","maxLength":100},"SourceSystem":{"type":"string","maxLength":100},"StartPage":{"maximum":9999,"type":"integer"},"StatusCode":{"type":"string","maxLength":30},"XeScanDoc":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit$XeScanDoc.json#"},"XeScanDocSet":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit$XeScanDocSet.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
