/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.UISet.XeUISet$XeUITemplateSet.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$UISetID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"LoadTrigger":{"type":"string","maxLength":100},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"UISetID":{"type":"integer","primaryKey":"generated"},"WellKnownID":{"type":"string","maxLength":50},"XeUITemplateSet":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.UISet.XeUISet$XeUITemplateSet.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$UISetID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$UISetID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
