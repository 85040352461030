/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest$XeBookingOrder.json#"},"Description":{"type":"string"},"IsAutoReorder":{"type":"boolean"},"IsBookingOrder":{"type":"boolean"},"IsOpenEnded":{"type":"boolean"},"IsScheduled":{"type":"boolean"},"OrderDateTime":{"format":"date-time","nullable":true,"type":"string"},"PlacedBy":{"type":"integer"},"RepeatPatternID":{"type":"string"},"RequestedBy":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest$XeBookingOrder.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest$XeBookingOrder.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
