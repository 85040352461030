/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddRequest.json#"},"Active":{"type":"boolean"},"Active_default":{"type":"string"},"AddInactive":{"type":"boolean"},"AddInactive_default":{"type":"string"},"CareTypeID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"IsDerived":{"type":"boolean"},"IsDerived_default":{"type":"string"},"IsFromVisit":{"type":"boolean"},"IsPreferred":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PatientPlanID":{"type":"string","maxLength":32},"ProviderID":{"type":"integer","primaryKey":"generated"},"ProviderTypeID":{"type":"string","maxLength":30},"RelationFlagBits":{"defaultValue":0,"maximum":9007199254740991,"type":"integer"},"StaffID":{"type":"integer"},"StaffID_EnterpriseID":{"type":"string"}},"required":["IPID","ProviderTypeID","StaffID"],"$id":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
