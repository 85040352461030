/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.report.XeReportDetail.json#"},"Active":{"type":"boolean"},"AssetName":{"type":"string","maxLength":50},"BookID":{"type":"string","maxLength":64},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DefaultOutPut":{"type":"string","maxLength":1},"Description":{"type":"string","maxLength":500},"FieldTransforms":{"type":"string","maxLength":100},"Fields":{"type":"string","maxLength":400},"FileID":{"type":"integer"},"FileName":{"type":"string","maxLength":200},"FilterValue":{"type":"string","maxLength":60},"GenerateLog":{"type":"string","maxLength":1},"GroupID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":100},"OutputType":{"type":"string","maxLength":10},"Parameters":{"type":"string","maxLength":200},"Permissions":{"type":"string","maxLength":200},"Printer":{"type":"string","maxLength":50},"Query":{"type":"string","maxLength":500},"ReportCode":{"type":"string","maxLength":128},"ReportID":{"type":"integer","primaryKey":"generated"},"ReportTypeID":{"type":"integer"},"SystemCodes":{"type":"string","maxLength":50},"TemplateType":{"type":"string","maxLength":10},"Url":{"type":"string","maxLength":200},"XeSmartBookInstance":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.report.XeReportDetail.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.report.XeReportDetail.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
