/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$DocTypeID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DocTypeID":{"type":"string","primaryKey":"supplied","maxLength":30},"DocTypeIDOperator":{"type":"string"},"IsIPIDAllowed":{"type":"boolean"},"IsIPIDRequired":{"type":"boolean"},"IsObjectType":{"type":"string"},"IsRootType":{"type":"boolean"},"LinkAssetName":{"type":"string","maxLength":50},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":100}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$DocTypeID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$DocTypeID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
