/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$AppliedCoveragePlan.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$CoveragePlan.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage.json#"},"AppliedCoveragePlan":{"$ref":"com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$AppliedCoveragePlan.json#"},"CoveragePlan":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$CoveragePlan.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
