/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.orderbase.XeOrderBase_UM$XeOrderCodes.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"Code":{"type":"string","primaryKey":"supplied","maxLength":50},"CodeModifierRecordID":{"type":"string","maxLength":32},"CodeRecordID":{"type":"string","maxLength":32},"CodeTypeID":{"type":"string","primaryKey":"supplied","maxLength":30},"CodeTypeIDFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DiagnosisID":{"type":"string","maxLength":32},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":500},"OrderID":{"type":"integer","primaryKey":"supplied"},"Sequence":{"exclusiveMaximum":100000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.orderbase.XeOrderBase_UM$XeOrderCodes.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.orderbase.XeOrderBase_UM$XeOrderCodes.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
