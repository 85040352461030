/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$AssessmentID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitAssessment.json#"},"AssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$AssessmentID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EnteredDate":{"format":"date-time","nullable":true,"type":"string"},"IVID":{"type":"integer"},"IVIDJoin":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ScanDocID":{"type":"integer"},"ScanDocIDFilter":{"type":"string"},"SignedDate":{"format":"date-time","nullable":true,"type":"string"},"VisitAssessmentID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitAssessment.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitAssessment.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
