/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$BillingProviderID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$PerformProviderID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$XeEncFormDx.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$XePatient.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx.json#"},"Active":{"type":"boolean"},"BillingProviderID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$BillingProviderID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EncounterDate":{"format":"date","nullable":true,"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PerformProviderID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$PerformProviderID.json#"},"Status":{"type":"string","maxLength":50},"XeEncFormDx":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$XeEncFormDx.json#"},"type":"array"},"XePatient":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx$XePatient.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
