/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest$XePatientAddress.json#"},"Active":{"type":"boolean"},"AddressID":{"type":"string","primaryKey":"generated","maxLength":32},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"AddressLine3":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"ContactInfoTypeID":{"type":"string","maxLength":16},"Coordinates":{"type":"string","maxLength":0},"CountryID":{"type":"string","maxLength":16},"CountyFIPS":{"type":"string","maxLength":20},"CountyName":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Email":{"type":"string","maxLength":60},"Fax":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"IsHomeless":{"type":"boolean"},"IsPreferred":{"type":"boolean"},"IsPrescribing":{"type":"boolean"},"MobilePhone":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NormalizeResponse":{"type":"string","maxLength":500},"Notes":{"type":"string","maxLength":500},"OriginalAddress":{"type":"string","maxLength":2147483647},"Phone":{"type":"string","maxLength":30},"PreferredMethod":{"type":"string","maxLength":16},"StateFIPS":{"type":"string","maxLength":20},"StateProvince":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest$XePatientAddress.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest$XePatientAddress.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
