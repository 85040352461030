/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XeScanDocSet.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"BarCode":{"type":"string","maxLength":100},"CategoryID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":200},"DocumentTypeID":{"type":"string","maxLength":30},"ExternalID":{"type":"string","maxLength":200},"FileID":{"type":"integer"},"FileName":{"type":"string","maxLength":200},"FromDate":{"format":"date-time","nullable":true,"type":"string"},"IncomingPhone":{"type":"string","maxLength":30},"ItemID":{"type":"integer","primaryKey":"generated"},"LockDateTime":{"format":"date-time","nullable":true,"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OCRFileID":{"type":"integer"},"PageCount":{"maximum":9999,"type":"integer"},"ScanDocID":{"type":"integer"},"SourceFileID":{"type":"integer"},"SourcePhone":{"type":"string","maxLength":30},"UploadDate":{"format":"date-time","nullable":true,"type":"string"},"UploadUser":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XeScanDocSet.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XeScanDocSet.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
