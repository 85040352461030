/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView$ProviderID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json#"},"ActivityAsset":{"type":"string"},"ActivityDate":{"format":"date-time","nullable":true,"type":"string"},"ActivityDateEnd":{"format":"date-time","nullable":true,"type":"string"},"ActivityDateStart":{"format":"date-time","nullable":true,"type":"string"},"ActivityName":{"type":"string"},"As":{"type":"string"},"CaseIVID":{"type":"integer"},"Description":{"type":"string"},"Duration":{"maximum":99999,"type":"integer"},"EnterpriseID":{"type":"string"},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IncludeAttachmentsData":{"type":"string"},"IncludeCallsData":{"type":"string"},"IncludeCaseData":{"type":"string"},"IncludeCorrespondencesData":{"type":"string"},"IncludeDocumentsData":{"type":"string"},"IncludeHRAData":{"type":"string"},"IncludeMedRecData":{"type":"string"},"IncludePOCData":{"type":"string"},"IncludeVisitData":{"type":"string"},"IsActiveStatus":{"type":"string"},"Label":{"type":"string"},"Owner":{"type":"string"},"ParentKey":{"type":"string"},"PrimaryKey":{"type":"string"},"PriorityID":{"type":"string"},"PriorityIDNot":{"type":"string"},"PriorityIDOperator":{"type":"string"},"ProviderID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView$ProviderID.json#"},"ProviderIDJoin":{"type":"string"},"QuerySort":{"type":"string"},"ReferredIVID":{"type":"integer"},"RenderColor":{"type":"string"},"RenderStyle":{"type":"string"},"RestrictValue":{"type":"string"},"SecondaryKey":{"type":"string"},"Sequence":{"maximum":99999,"type":"integer"},"Status":{"type":"string"},"StatusName":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
