/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientVisitProviderAddRequest.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EndDate":{"format":"date-time","nullable":true,"type":"string"},"IVID":{"type":"integer"},"IsOriginalResp":{"type":"string"},"IsUniqueByProviderTypeID":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NextReviewComment":{"type":"string","maxLength":500},"NextReviewDate":{"format":"date-time","nullable":true,"type":"string"},"PatientContactID":{"type":"integer"},"PatientContactID_EnterpriseID":{"type":"string"},"PriorityID":{"type":"string","maxLength":16},"ProviderTypeID":{"type":"string","maxLength":30},"RelationFlagBits":{"defaultValue":0,"maximum":9007199254740991,"type":"integer"},"ReturnAllUpdated":{"type":"string"},"ServiceBookingID":{"type":"integer"},"StaffID":{"type":"integer"},"StaffID_EnterpriseID":{"type":"string"},"StartDate":{"format":"date-time","nullable":true,"type":"string"},"SubCategoryID":{"type":"string","maxLength":30},"TimeSheetID":{"type":"integer"},"VisitProviderID":{"type":"integer","primaryKey":"generated"},"VisitProviderSource":{"type":"integer"},"WaitListID":{"type":"integer"},"WaitListSubCategoryID":{"maximum":9007199254740991,"type":"integer"}},"required":["IVID","ProviderTypeID"],"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientVisitProviderAddRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientVisitProviderAddRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
