/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.XePatientIssueTask.json#"},"Active":{"type":"boolean"},"Comments":{"type":"string","maxLength":1024},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EndDate":{"format":"date-time","nullable":true,"type":"string"},"IssueIVID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"StartDate":{"format":"date-time","nullable":true,"type":"string"},"TaskID":{"type":"integer","primaryKey":"generated"},"TaskTypeID":{"maximum":9007199254740991,"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.XePatientIssueTask.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.XePatientIssueTask.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
