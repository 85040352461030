/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$CatalogID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$PlacerID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeCallLog.json#"},"Active":{"type":"boolean"},"CallLogID":{"type":"integer","primaryKey":"generated"},"CallToName":{"type":"string","maxLength":100},"CatalogID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$CatalogID.json#"},"Comments":{"type":"string","maxLength":2000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IVID":{"type":"integer"},"IsChecked":{"type":"boolean"},"IsVisit":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"PlacerID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$PlacerID.json#"},"RestrictValue":{"maximum":99999,"type":"integer"},"StartDateTime":{"format":"date-time","nullable":true,"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeCallLog.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeCallLog.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
