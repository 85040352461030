/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$CodeRecordID.json#"},"Code":{"type":"string","maxLength":50},"CodeRecordID":{"maximum":9007199254740991,"type":"integer","primaryKey":"supplied"},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EffectiveDate":{"format":"date","nullable":true,"type":"string"},"ExpireDate":{"format":"date","nullable":true,"type":"string"},"MediumDesc":{"type":"string","maxLength":500},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":2000}},"required":["Code"],"$id":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$CodeRecordID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$CodeRecordID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
