/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XePatientVisit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeAuth.json#"},"Active":{"type":"boolean"},"AuthNum":{"type":"string","maxLength":30},"AuthSubTypeID":{"type":"string","maxLength":100},"AuthTypeID":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IVID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"UmUrgencyID":{"type":"string","maxLength":32},"XePatientVisit":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XePatientVisit.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeAuth.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeAuth.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
