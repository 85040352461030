/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.staff.BaseXeStaff$EnterpriseID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormFull$ReferringProviderID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string","maxLength":100},"EnterpriseID":{"oneOf":[{"nullable":true,"type":"string"},{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.BaseXeStaff$EnterpriseID.json#"}]},"FamilyName":{"type":"string","maxLength":100},"FamilyNameSearchGroup":{"type":"string"},"GivenName":{"type":"string","maxLength":100},"GivenNameSearchGroup":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"type":"string","maxLength":16},"NationalProviderID":{"type":"string","maxLength":10},"RestrictValue":{"maximum":99999,"type":"integer"},"SecondName":{"type":"string","maxLength":100},"StaffID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormFull$ReferringProviderID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormFull$ReferringProviderID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
