/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderResponse.json#"},"Active":{"type":"boolean"},"CareTypeID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"IsFromVisit":{"type":"boolean"},"IsPreferred":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ProviderID":{"type":"integer","primaryKey":"generated"},"ProviderTypeID":{"type":"string","maxLength":30},"RestrictValue":{"maximum":99999,"type":"integer"},"StaffID":{"type":"integer"},"StaffID_EnterpriseID":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderResponse.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
