/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest$XePatientPlan.json#"},"Active":{"type":"boolean"},"Description":{"type":"string","maxLength":500},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"ExpireDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"ExpireDateTimeOff":{"type":"string"},"GroupID":{"type":"string","maxLength":60},"HICN":{"type":"string","maxLength":20},"HealthPlanID":{"type":"string","maxLength":32},"MemberID":{"type":"string","maxLength":60},"PlanID":{"type":"string","maxLength":60},"PriorityOrder":{"maximum":9999,"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest$XePatientPlan.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest$XePatientPlan.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
