/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest$XeServiceBookingResource.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Duration":{"maximum":99999999,"type":"integer"},"EndTime":{"format":"date-time","nullable":true,"type":"string"},"IsBookingProvider":{"type":"boolean"},"IsPrimary":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ResSchedTypeID":{"type":"integer"},"ResourceID":{"type":"integer"},"SchedEventID":{"type":"integer"},"ServiceBookingID":{"type":"integer"},"ServiceBookingResourceID":{"type":"integer","primaryKey":"generated"},"StartTime":{"format":"date-time","nullable":true,"type":"string"},"TimeOffset":{"maximum":99999999,"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest$XeServiceBookingResource.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest$XeServiceBookingResource.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
