/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$DocTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$IPID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$StatusCode.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeAuth.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientIssue.json';
import schema_5 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitAssessment.json';
import schema_6 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitCaseManagement.json';
import schema_7 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitCaseManagementProgram.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$ScanDocID.json#"},"Active":{"type":"boolean"},"Comments":{"type":"string","maxLength":1000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DocTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$DocTypeID.json#"},"ErrorDescription":{"type":"string","maxLength":500},"IPID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$IPID.json#"}]},"IsInError":{"type":"boolean"},"IsLinked":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ScanDocID":{"type":"integer","primaryKey":"generated"},"SourceID":{"type":"string","maxLength":100},"SourceSystem":{"type":"string","maxLength":100},"StatusCode":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$StatusCode.json#"},"XeAuth":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeAuth.json#"},"XePatientIssue":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientIssue.json#"},"XeVisitAssessment":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitAssessment.json#"},"XeVisitCaseManagement":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitCaseManagement.json#"},"XeVisitCaseManagementProgram":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitCaseManagementProgram.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$ScanDocID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$ScanDocID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5, schema_6, schema_7]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
