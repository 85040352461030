/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest$Parameter.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest$Report.json#"},"File":{"type":"string","maxLength":100},"FileID":{"type":"integer"},"FilenamePrefix":{"type":"string","maxLength":100},"Locale":{"defaultValue":"en","type":"string","maxLength":10},"Name":{"type":"string"},"OutputType":{"type":"string","maxLength":10},"Parameter":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest$Parameter.json#"},"type":"array"},"ReportCode":{"type":"string","maxLength":50},"ReportID":{"type":"integer"},"SaveInstance":{"defaultValue":false,"type":"boolean"},"UseSysDateFormat":{"defaultValue":false,"type":"boolean"},"XeSmartBookInstance":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest$Report.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest$Report.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
