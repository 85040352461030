/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$SpecialtyID.json#"},"ID":{"type":"integer","primaryKey":"generated"},"IDAs":{"type":"string"},"Inline":{"type":"string"},"Name":{"type":"string","maxLength":128}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$SpecialtyID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$SpecialtyID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
