/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$DocTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$IPID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$IVID.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeEnterpriseData.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeScanDocSet.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"Comments":{"type":"string","maxLength":1000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DocTypeID":{"oneOf":[{"nullable":true,"type":"string"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$DocTypeID.json#"}]},"IPID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$IPID.json#"}]},"IVID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$IVID.json#"}]},"IVIDTableIdentifier":{"type":"string"},"IsLinked":{"type":"boolean"},"IsLinkedFilter":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ScanDocID":{"type":"integer","primaryKey":"generated"},"SourceID":{"type":"string","maxLength":100},"SourceSystem":{"type":"string","maxLength":100},"StatusCode":{"type":"string","maxLength":30},"VisitAssessmentID":{"type":"string"},"XeEnterpriseData":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeEnterpriseData.json#"},"XeScanDocSet":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc$XeScanDocSet.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
