/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientVisit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeAuth.json#"},"AuthNum":{"type":"string","maxLength":30},"AuthTypeID":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IVID":{"type":"integer","primaryKey":"supplied"},"IVIDJoin":{"type":"string"},"ItemID":{"type":"integer"},"ItemIDJoin":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ReceivedDate":{"format":"date-time","nullable":true,"type":"string"},"ScanDocID":{"type":"integer"},"ScanDocIDFilter":{"type":"string"},"XePatientVisit":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientVisit.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeAuth.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeAuth.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
