/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$ServiceID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$Status.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeAuthorization.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XePatientIssue.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeVisitCaseManagement.json';
import schema_5 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeVisitCaseManagementProgram.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XePatientVisit.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EndDate":{"format":"local-date-time","nullable":true,"type":"string"},"EndDateOff":{"type":"string"},"IPID":{"type":"integer"},"IVID":{"type":"integer","primaryKey":"generated"},"IsInError":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ParentID":{"type":"integer"},"ParentIDFilter":{"type":"string"},"QuerySort":{"type":"string"},"ServiceID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$ServiceID.json#"},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"Status":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$Status.json#"},"VisitID":{"type":"string","maxLength":60},"VisitTypeID":{"type":"string","maxLength":16},"XeAuthorization":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeAuthorization.json#"},"XePatientIssue":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XePatientIssue.json#"},"XePatientVisit":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XePatientVisit.json#"},"type":"array"},"XeVisitCaseManagement":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeVisitCaseManagement.json#"},"XeVisitCaseManagementProgram":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeVisitCaseManagementProgram.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XePatientVisit.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XePatientVisit.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
