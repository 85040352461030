/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.diagnosis.SearchXeDiagnosis.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.diagnosiscatalog.XeDiagnosisCatalogWithItems.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CatalogID":{"type":"integer","primaryKey":"supplied"},"CatalogTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DiagnosisID":{"type":"string","maxLength":32},"IsExpSet":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"ParentID":{"type":"integer"},"RightID":{"type":"string","maxLength":60},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"},"XeDiagnosis":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.diagnosis.SearchXeDiagnosis.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.diagnosiscatalog.XeDiagnosisCatalogWithItems.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.diagnosiscatalog.XeDiagnosisCatalogWithItems.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
