/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFromAuthOrClaim.json#"},"AuthIVID":{"type":"integer"},"ClaimID":{"type":"integer"},"ClaimID_EnterpriseID":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":1024},"DescriptionMaxSize":{"type":"string"},"DueDateTime":{"format":"date-time","nullable":true,"type":"string"},"IPID":{"type":"integer"},"IssueIVID":{"type":"integer","primaryKey":"supplied"},"IssueSubTypeID":{"type":"string","maxLength":16},"IssueTypeID":{"type":"string","maxLength":16},"LevelOfServiceID":{"type":"string","maxLength":16},"MethodID":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Owner":{"type":"integer"},"OwnerCacheKey":{"type":"string"},"PatientPlanID":{"type":"string","maxLength":32},"PriorityID":{"type":"string","maxLength":32},"ReceivedDateTime":{"format":"date-time","nullable":true,"type":"string"},"RequestorCatID":{"type":"string","maxLength":30},"Status":{"type":"string","maxLength":16},"TeamID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFromAuthOrClaim.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFromAuthOrClaim.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
