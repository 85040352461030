/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$ServiceID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$XeWorkflowInstance.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$OrderID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsBookingOrder":{"type":"string","maxLength":1},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OrderID":{"type":"integer","primaryKey":"generated"},"ServiceID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$ServiceID.json#"},"ServiceIDInline":{"type":"string"},"WorkID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$XeWorkflowInstance.json#"}]},"WorkflowID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$OrderID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$OrderID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
