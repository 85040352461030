/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact$NameTitleID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact$ProviderTypeID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact$XeStaffAddress.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact.json#"},"Active":{"type":"boolean"},"Credentials":{"type":"string","maxLength":100},"EnterpriseID":{"type":"string","maxLength":30},"ExternalID":{"type":"string","maxLength":50},"ExternalIDNot":{"type":"string"},"ExternalIDOperator":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IVID":{"type":"integer"},"IsPatientProviderOtherAccount":{"type":"boolean"},"IsTeamContact":{"type":"boolean"},"IsValid":{"type":"boolean"},"NameTitleID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact$NameTitleID.json#"}]},"ProviderID":{"type":"integer"},"ProviderTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact$ProviderTypeID.json#"}]},"QuerySort":{"type":"string"},"ResourceTypeID":{"type":"string","maxLength":50},"SecondName":{"type":"string","maxLength":100},"SignatureFileID":{"type":"integer"},"StaffID":{"type":"integer"},"StaffIDOperator":{"type":"string"},"StaffMasterID":{"type":"string","maxLength":128},"VisitProviderID":{"type":"integer"},"XeStaffAddress":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact$XeStaffAddress.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
