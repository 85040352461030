/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$Transition.json#"},"isMultiWork":{"type":"string"},"name":{"type":"string"},"rightID":{"type":"string"},"sequence":{"maximum":999,"type":"integer"},"to":{"type":"string"},"tokenID":{"type":"string"},"transitionElement":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$Transition.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$Transition.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
