/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider$ProviderTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider$StaffID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider$TimeSheetID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json#"},"Active":{"type":"boolean"},"Application":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EndDate":{"format":"date-time","nullable":true,"type":"string"},"IVID":{"type":"integer"},"IsOnline":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ProviderTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider$ProviderTypeID.json#"}]},"StaffID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider$StaffID.json#"},"StaffID_EnterpriseID":{"type":"string"},"StartDate":{"format":"date-time","nullable":true,"type":"string"},"TimeSheetID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider$TimeSheetID.json#"},"VisitProviderID":{"type":"integer","primaryKey":"generated"}},"required":["IVID"],"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
