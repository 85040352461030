/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$ReportContent.json#"},"Data":{"type":"string"},"Description":{"type":"string"},"FileName":{"type":"string"},"FileSize":{"type":"string"},"FileType":{"type":"string"},"FromDate":{"format":"date-time","nullable":true,"type":"string"},"IsBase64":{"type":"string"},"MimeType":{"type":"string"},"StatusCode":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$ReportContent.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$ReportContent.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
