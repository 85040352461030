/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueListResponse$HealthPlanID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueListResponse$PatientPlanID.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"EnrollmentDate":{"format":"date","nullable":true,"type":"string"},"ExpireDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"ExpireDateTimeOff":{"type":"string"},"GroupID":{"type":"string","maxLength":60},"HICN":{"type":"string","maxLength":20},"HealthPlanID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueListResponse$HealthPlanID.json#"},"HealthPlanIDInline":{"type":"string"},"MemberID":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PatientPlanID":{"type":"string","primaryKey":"generated","maxLength":32},"PlanID":{"type":"string","maxLength":60},"RestrictValue":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueListResponse$PatientPlanID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueListResponse$PatientPlanID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
