/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$HCU.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XePatientVisitPlan.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XeVisitCaseManagementProgram.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XeVisitDiagnosis.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$ReadmitIVID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DisplayRoomBed":{"type":"string","maxLength":50},"EndDate":{"format":"local-date-time","nullable":true,"type":"string"},"EndDateOff":{"type":"string"},"ExpectedEndDate":{"format":"local-date-time","nullable":true,"type":"string"},"ExpectedEndDateOff":{"type":"string"},"HCU":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$HCU.json#"},"IVID":{"type":"integer","primaryKey":"generated"},"IsCMgmtPatient":{"type":"boolean"},"IsReadmit":{"type":"string","maxLength":1},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ReadmitDays":{"maximum":999,"type":"integer"},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"VisitID":{"type":"string","maxLength":60},"XePatientVisitPlan":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XePatientVisitPlan.json#"},"XePatientVisitProvider":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json#"},"type":"array"},"XeVisitCaseManagementProgram":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XeVisitCaseManagementProgram.json#"},"type":"array"},"XeVisitDiagnosis":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XeVisitDiagnosis.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$ReadmitIVID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$ReadmitIVID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
