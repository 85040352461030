/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormBrowseRequest.json#"},"Active":{"type":"boolean"},"BillingProviderID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EncFormID":{"type":"integer","primaryKey":"generated"},"EncounterDateMax":{"format":"date","nullable":true,"type":"string"},"EncounterDateMin":{"format":"date","nullable":true,"type":"string"},"IPID":{"type":"integer"},"IsDraft":{"type":"boolean"},"IsIncludeInactiveComponents":{"type":"boolean"},"LocationEnterpriseID":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PerformProviderID":{"type":"integer"},"QuerySort":{"type":"string"},"ReportID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormBrowseRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormBrowseRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
