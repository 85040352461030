/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$LanguageID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$WrittenLanguageID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XePatientAddress.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$IPID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DoB":{"format":"local-date-time","nullable":true,"type":"string"},"DoBOff":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"integer","primaryKey":"supplied"},"Join":{"type":"string"},"LanguageID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$LanguageID.json#"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NameTitleID":{"type":"string","maxLength":16},"PatientID":{"type":"string","maxLength":60},"SecondName":{"type":"string","maxLength":100},"SexID":{"type":"string","maxLength":16},"Suffix":{"type":"string","maxLength":10},"ThirdName":{"type":"string","maxLength":100},"WrittenLanguageID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$WrittenLanguageID.json#"},"XePatientAddress":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XePatientAddress.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$IPID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$IPID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
