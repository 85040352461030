/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$CodeRecordID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPxDx.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPxMod.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPx.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"Amount":{"exclusiveMaximum":10000000000,"type":"number"},"CodeRecordID":{"oneOf":[{"nullable":true,"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$CodeRecordID.json#"}]},"Comments":{"type":"string","maxLength":500},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EncFormPxID":{"type":"integer","primaryKey":"generated"},"IsVisitCharge":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Quantity":{"exclusiveMaximum":10000000000,"type":"number"},"StatusID":{"type":"string","maxLength":30},"UnitPrice":{"exclusiveMaximum":10000000000,"type":"number"},"XeEncFormPxDx":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPxDx.json#"},"type":"array"},"XeEncFormPxMod":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPxMod.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPx.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPx.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
