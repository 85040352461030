/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$AssessmentID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$SignedBy.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeVisitAssessment.json#"},"Active":{"type":"boolean"},"AssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$AssessmentID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IVID":{"type":"integer"},"InteralCode":{"type":"string"},"IsChecked":{"type":"boolean"},"IsInError":{"type":"boolean"},"IsVisit":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"RestrictValue":{"maximum":99999,"type":"integer"},"SignedBy":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$SignedBy.json#"},"SignedDate":{"format":"date-time","nullable":true,"type":"string"},"VisitAssessmentID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeVisitAssessment.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeVisitAssessment.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
