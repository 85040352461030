/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.PatientAddEligibleResponse.json#"},"EnterpriseID":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"RestrictProcessed":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.PatientAddEligibleResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientAddEligibleResponse.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
