/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$IPID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$IVID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EndDate":{"format":"local-date-time","nullable":true,"type":"string"},"EndDateOff":{"type":"string"},"ExpectedEndDate":{"format":"local-date-time","nullable":true,"type":"string"},"ExpectedEndDateOff":{"type":"string"},"IPID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$IPID.json#"},"IVID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"VisitID":{"type":"string","maxLength":60}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$IVID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$IVID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
