/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$NameTitleID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$XeUserData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$StaffID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string","maxLength":100},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$NameTitleID.json#"}]},"StaffID":{"type":"integer","primaryKey":"supplied"},"XeUserData":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$XeUserData.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$StaffID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient$StaffID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
