/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json#"},"Abbr":{"type":"string","maxLength":20},"AbbrSearchGroup":{"type":"string"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"CatContentTypeID":{"type":"string"},"Code":{"type":"string","maxLength":50},"CodeAlias":{"type":"string"},"CodeAliasSearchGroup":{"type":"string"},"CodeRecordID":{"type":"string","primaryKey":"supplied","maxLength":32},"CodeSearchGroup":{"type":"string"},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":2000},"DescriptionSearchGroup":{"type":"string"},"EffectiveDate":{"format":"date","nullable":true,"type":"string"},"EffectiveDateOperator":{"type":"string"},"EnterpriseID":{"type":"string"},"ExpireDate":{"format":"date","nullable":true,"type":"string"},"ExpireDateOperator":{"type":"string"},"IsModifier":{"type":"boolean"},"IsSelectable":{"type":"boolean"},"Keywords":{"type":"string"},"MediumDesc":{"type":"string","maxLength":500},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":2000},"NameSearchGroup":{"type":"string"},"ServiceSubTypeID":{"type":"string"},"ServiceTypeID":{"type":"string","maxLength":32},"ShortDesc":{"type":"string","maxLength":200}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
