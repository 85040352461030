/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDocSet.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":200},"DescriptionClearOnCopy":{"type":"string"},"DocTypeID":{"type":"string"},"DocTypeIDClearOnCopy":{"type":"string"},"FileID":{"type":"integer","primaryKey":"supplied"},"FileIDClearOnCopy":{"type":"string"},"FileName":{"type":"string","maxLength":200},"FileNameClearOnCopy":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PageCount":{"maximum":9999,"type":"integer"},"ScanDocID":{"type":"integer","primaryKey":"supplied"},"ScanDocIDClearOnCopy":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDocSet.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDocSet.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
