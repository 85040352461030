/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientAddressHistory.json#"},"Active":{"type":"string"},"ActiveFilter":{"type":"string"},"AddressLine1":{"type":"string"},"AddressLine2":{"type":"string"},"City":{"type":"string"},"ContactInfoTypeID":{"type":"string"},"CountryID":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"Email":{"type":"string"},"EnterpriseID":{"type":"string"},"Fax":{"type":"string"},"IsPreferred":{"type":"string"},"Phone":{"type":"string"},"QuerySort":{"type":"string"},"StateProvince":{"type":"string"},"ZipPostalCode":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientAddressHistory.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientAddressHistory.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
