/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$PatientContactID.json#"},"Active":{"type":"boolean"},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"AddressLine3":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"Comments":{"type":"string","maxLength":500},"CountryID":{"type":"string","maxLength":16},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Email":{"type":"string","maxLength":60},"FamilyName":{"type":"string","maxLength":100},"Fax":{"type":"string","maxLength":30},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"integer"},"MobilePhone":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"PatientContactID":{"type":"integer","primaryKey":"generated"},"Phone":{"type":"string","maxLength":30},"RelationFlagBits":{"maximum":9007199254740991,"type":"integer"},"RelationTypeDesc":{"type":"string","maxLength":30},"RelationTypeID":{"type":"string","maxLength":16},"StateProvince":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$PatientContactID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$PatientContactID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
