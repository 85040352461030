/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patient.PatientCcdaResponse$XeAppUserNotificationFolder.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.PatientCcdaResponse.json#"},"BlobDescription":{"type":"string","maxLength":200},"BlobPrimaryAsset":{"type":"string","maxLength":50},"BlobPrimaryAssetID":{"type":"string","maxLength":50},"BlobPrimaryEnterpriseID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EscalationRight":{"type":"string","maxLength":200},"IsPrivate":{"type":"boolean"},"IsThreadRoot":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NotificationID":{"type":"integer","primaryKey":"generated"},"NotificationTypeID":{"type":"string","maxLength":60},"Priority":{"type":"string"},"Sender":{"type":"string"},"Subject":{"type":"string","maxLength":300},"ThreadID":{"type":"integer"},"XeAppUserNotificationFolder":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.PatientCcdaResponse$XeAppUserNotificationFolder.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.PatientCcdaResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientCcdaResponse.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
