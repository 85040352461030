/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$BenefitLimit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$CoveragePlan.json#"},"BenefitLimit":{"$ref":"com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$BenefitLimit.json#"},"EffectiveDate":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateOff":{"type":"string"},"ExpireDate":{"format":"local-date-time","nullable":true,"type":"string"},"ExpireDateOff":{"type":"string"},"IsExternalCOB":{"type":"boolean"},"LobID":{"type":"string"},"PlanID":{"type":"string"},"PriorityOrder":{"maximum":999,"type":"integer"},"PurchaseOrRental":{"type":"string"},"PurchaseUnitPrice":{"exclusiveMaximum":1000000,"type":"number"},"RentalUnitPrice":{"exclusiveMaximum":1000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$CoveragePlan.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.orderbase.BenefitsCoverage$CoveragePlan.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
