/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$XePatientAddress.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$IPID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DateOfDeath":{"format":"local-date-time","nullable":true,"type":"string"},"DateOfDeathOff":{"type":"string"},"DoB":{"format":"local-date-time","nullable":true,"type":"string"},"DoBOff":{"type":"string"},"EnterpriseID":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"type":"string","maxLength":16},"NationalID":{"type":"string","maxLength":30},"PatientID":{"type":"string","maxLength":60},"PersonID":{"type":"string","maxLength":128},"PreferredName":{"type":"string","maxLength":100},"RegistrationTypeID":{"type":"string","maxLength":16},"SecondName":{"type":"string","maxLength":100},"SexID":{"type":"string","maxLength":16},"Suffix":{"type":"string","maxLength":10},"XePatientAddress":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$XePatientAddress.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$IPID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$IPID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
