/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CatContentTypeID":{"type":"string","maxLength":16},"CatalogID":{"type":"integer","primaryKey":"supplied"},"CatalogTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsChargeCatalog":{"type":"boolean"},"IsExpSet":{"type":"boolean"},"IsOrderCatalog":{"type":"boolean"},"IsOrderSheetCatalog":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"QuerySort":{"type":"string"},"RightID":{"type":"string","maxLength":64},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"},"XeServiceCodeRefCatDerived":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
