/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse$HealthPlanID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse$XePatientPlan.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"GroupID":{"type":"string","maxLength":60},"HICN":{"type":"string","maxLength":20},"HICNSearchGroup":{"type":"string"},"HealthPlanID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse$HealthPlanID.json#"},"MemberID":{"type":"string","maxLength":60},"MemberIDSearchGroup":{"type":"string"},"PatientPlanID":{"type":"string","primaryKey":"generated","maxLength":32},"PlanID":{"type":"string","maxLength":60},"QuerySort":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse$XePatientPlan.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse$XePatientPlan.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
