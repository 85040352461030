/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$EnterpriseID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$NameTitleID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XeStaffAddress.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XeStaffSpecialty.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$StaffID.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string","maxLength":100},"DEANumber":{"type":"string","maxLength":10},"EnterpriseID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$EnterpriseID.json#"}]},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IsValid":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$NameTitleID.json#"}]},"NationalProviderID":{"type":"string","maxLength":10},"ResourceTypeID":{"type":"string","maxLength":50},"SecondName":{"type":"string","maxLength":100},"StaffID":{"type":"integer","primaryKey":"supplied"},"StaffMasterID":{"type":"string","maxLength":128},"XeStaffAddress":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XeStaffAddress.json#"},"type":"array"},"XeStaffSpecialty":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$XeStaffSpecialty.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$StaffID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery$StaffID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
