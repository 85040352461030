/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XeScanDoc.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$VisitAssessmentID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XePatientDoc.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"Authority":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DocumentNumber":{"type":"string","maxLength":100},"DocumentTypeID":{"type":"string","maxLength":30},"ExpiryDate":{"format":"date","nullable":true,"type":"string"},"IPID":{"type":"integer"},"IsDefault":{"type":"boolean"},"IsError":{"type":"boolean"},"IsErrorFilter":{"type":"string"},"IsIdentifier":{"type":"boolean"},"IssueDate":{"format":"date","nullable":true,"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NationalityID":{"type":"string","maxLength":16},"PatientContactID":{"type":"integer"},"PatientDocID":{"type":"integer","primaryKey":"generated"},"PlaceOfIssue":{"type":"string","maxLength":100},"RestrictValue":{"type":"string"},"ScanDocID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XeScanDoc.json#"}]},"StatusID":{"type":"string","maxLength":16},"VisitAssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$VisitAssessmentID.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XePatientDoc.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest$XePatientDoc.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
