/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.encformpx.XeEncFormPxWithDxMod$CodeRecordID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encformpx.XeEncFormPxWithDxMod$XeEncFormPxMod.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"CodeRecordID":{"oneOf":[{"nullable":true,"type":"string"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.encformpx.XeEncFormPxWithDxMod$CodeRecordID.json#"}]},"EncFormPxModID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encformpx.XeEncFormPxWithDxMod$XeEncFormPxMod.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encformpx.XeEncFormPxWithDxMod$XeEncFormPxMod.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
