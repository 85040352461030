/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json#"},"ActivityAsset":{"items":{"type":"string"},"type":"array"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DueEndDateTime":{"format":"date-time","nullable":true,"type":"string"},"DueStartDateTime":{"format":"date-time","nullable":true,"type":"string"},"ErrorDescription":{"type":"string","maxLength":1024},"IPID":{"type":"integer"},"IsInError":{"type":"boolean"},"IsInErrorDefault":{"type":"string"},"IssueIVID":{"type":"integer","primaryKey":"supplied"},"IssueNum":{"type":"string","maxLength":30},"IssueSubTypeID":{"type":"string","maxLength":16},"IssueTypeID":{"type":"string","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OffsetHours":{"type":"string"},"Owner":{"type":"integer"},"PriorityID":{"maximum":9007199254740991,"type":"integer"},"ReceivedEndDateTime":{"format":"date-time","nullable":true,"type":"string"},"ReceivedStartDateTime":{"format":"date-time","nullable":true,"type":"string"},"RequestorCatID":{"type":"string","maxLength":30},"ResolutionDesc":{"type":"string","maxLength":1024},"ResolutionID":{"type":"string","maxLength":16},"TeamID":{"type":"integer"},"WorkState":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
