/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientFamilyHistory$RecordedBy.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientFamilyHistory$XePatientFamilyNote.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveSLDefaultValue":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"HistoryLinkID":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NoteID":{"type":"integer","primaryKey":"generated"},"NoteTypeID":{"type":"string","maxLength":60},"Notes":{"type":"string","maxLength":2000},"RecordInfo":{"type":"string","maxLength":2000},"RecordedBy":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientFamilyHistory$RecordedBy.json#"}]},"RecordedDate":{"format":"date-time","nullable":true,"type":"string"},"VisitHistoryID":{"type":"string"}},"required":["Active"],"$id":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientFamilyHistory$XePatientFamilyNote.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientFamilyHistory$XePatientFamilyNote.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
