/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XePatientContact.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDoc.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact.json#"},"Active":{"defaultValue":true,"type":"boolean"},"Authority":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DocumentNumber":{"type":"string","maxLength":100},"DocumentTypeID":{"type":"string","maxLength":30},"ErrorDescription":{"type":"string","maxLength":500},"ExpiryDate":{"format":"date","nullable":true,"type":"string"},"IPID":{"type":"integer"},"InstanceAsset":{"type":"string"},"IsCopyObtained":{"defaultValue":false,"type":"boolean"},"IsDefault":{"defaultValue":true,"type":"boolean"},"IsError":{"defaultValue":false,"type":"boolean"},"IsIdentifier":{"defaultValue":"n","type":"string"},"IssueDate":{"format":"date","nullable":true,"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NationalityID":{"type":"string","maxLength":16},"PatientDocID":{"type":"integer","primaryKey":"generated"},"PatientDocIDClearOnCopy":{"type":"string"},"PhysicalLocation":{"type":"string","maxLength":100},"PlaceOfIssue":{"type":"string","maxLength":100},"ScanDocID":{"type":"integer"},"SignedDate":{"format":"date","nullable":true,"type":"string"},"StatusID":{"type":"string","maxLength":16},"StatusReason":{"type":"string","maxLength":100},"VisitAssessmentID":{"type":"integer"},"VisitAssessmentIDClearOnCopy":{"type":"string"},"XePatientContact":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XePatientContact.json#"},"type":"array"},"XeScanDoc":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDoc.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
