/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$Owner.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$ProgramID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$Status.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XeVisitCaseManagementProgram.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EndDate":{"format":"local-date-time","nullable":true,"type":"string"},"EndDateOff":{"type":"string"},"ExpectedEndDate":{"format":"local-date-time","nullable":true,"type":"string"},"ExpectedEndDateOff":{"type":"string"},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsInError":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Owner":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$Owner.json#"},"ProgramID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$ProgramID.json#"},"ReferredIVID":{"type":"integer"},"ReferredIVID_EnterpriseID":{"type":"string"},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"Status":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$Status.json#"},"VisitCaseMgmtProgramID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XeVisitCaseManagementProgram.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$XeVisitCaseManagementProgram.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
