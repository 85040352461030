/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.encformpx.AbstractXeEncFormPx$CodeRecordID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$StatusID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$XeEncFormPx.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"Amount":{"exclusiveMaximum":10000000000,"type":"number"},"CodeRecordID":{"oneOf":[{"nullable":true,"type":"string"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.encformpx.AbstractXeEncFormPx$CodeRecordID.json#"}]},"Comments":{"type":"string","maxLength":500},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EncFormPxID":{"type":"integer","primaryKey":"generated"},"IsVisitCharge":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Quantity":{"exclusiveMaximum":10000000000,"type":"number"},"StatusID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$StatusID.json#"}]},"StatusIDAsset":{"type":"string"},"UnitPrice":{"exclusiveMaximum":10000000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$XeEncFormPx.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse$XeEncFormPx.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
