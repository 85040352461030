/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$FacilityID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormDx.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPx.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm.json#"},"Active":{"defaultValue":true,"type":"boolean"},"BillingProviderID":{"type":"integer"},"Comments":{"type":"string","maxLength":1000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EncFormID":{"type":"integer","primaryKey":"generated"},"EncounterDate":{"format":"date","nullable":true,"type":"string"},"FacilityID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$FacilityID.json#"}]},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsDraft":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"OverrideValidation":{"defaultValue":"n","type":"string","maxLength":1},"PerformProviderID":{"type":"integer"},"PointOfServiceID":{"type":"integer"},"ReferringProviderID":{"type":"integer"},"ReferringProviderID_EnterpriseID":{"type":"string"},"ReferringProviderName":{"type":"string","maxLength":100},"RelatedVisitIVID":{"type":"integer"},"RelatedVisitIVID_EnterpriseID":{"type":"string"},"SBCodeID":{"maximum":9007199254740991,"type":"integer"},"SBDate":{"format":"date","nullable":true,"type":"string"},"Status":{"type":"string","maxLength":50},"ValidateCodeID":{"type":"string","maxLength":100},"ValidateSucceed":{"defaultValue":"y","type":"string","maxLength":1},"XeEncFormDx":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormDx.json#"},"type":"array"},"XeEncFormPx":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm$XeEncFormPx.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
