/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$EventPartID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$XeEventPart.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$SchedEventID.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":1024},"EndTime":{"type":"string","maxLength":60},"EventClassID":{"type":"string","maxLength":16},"EventPartID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$EventPartID.json#"},"EventStatusID":{"type":"string","maxLength":16},"EventTypeID":{"defaultValue":"EVENT","type":"string","maxLength":16},"ExternalID":{"type":"string","maxLength":100},"IsInterval":{"defaultValue":true,"type":"boolean"},"IsOpaque":{"defaultValue":true,"type":"boolean"},"IsRecurring":{"type":"boolean"},"Location":{"type":"string","maxLength":256},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ParentSchedEventID":{"type":"integer"},"ParentSchedEventIDOperator":{"type":"string"},"Priority":{"maximum":99999,"type":"integer"},"SchedEventID":{"type":"integer","primaryKey":"generated"},"SchedEventUID":{"type":"string","maxLength":200},"StartTime":{"type":"string","maxLength":60},"Summary":{"type":"string","maxLength":200},"TableIdentifier":{"type":"string"},"XeEventPart":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$XeEventPart.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$SchedEventID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$SchedEventID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
