/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.drg.SearchXgDRG.json#"},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DRGID":{"type":"string","primaryKey":"supplied","maxLength":60},"Description":{"type":"string","maxLength":1200},"DescriptionSearchGroup":{"type":"string"},"EffectiveDate":{"defaultValue":"2009-01-01","format":"date","type":"string"},"EffectiveDateOperator":{"type":"string"},"ExpireDate":{"defaultValue":"2999-12-31","format":"date","type":"string"},"ExpireDateOperator":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"RestrictValue":{"defaultValue":1,"maximum":99999,"type":"integer"}},"required":["Active","Code","CodeTypeID","EffectiveDate","ExpireDate","RestrictValue"],"$id":"com.thrasys.xnet.erp.xmlobjects.drg.SearchXgDRG.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.drg.SearchXgDRG.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
