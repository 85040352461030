/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.XeServiceCodeCatalog.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived$XeRefCatModifier.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"ActiveXeValue":{"type":"string"},"CatItemID":{"type":"integer","primaryKey":"supplied"},"CatItemIDFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"},"XeServiceCodeCatalog":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.XeServiceCodeCatalog.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived$XeRefCatModifier.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived$XeRefCatModifier.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
