/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$ProgramID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientVisit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitCaseManagementProgram.json#"},"CaseIVID":{"type":"integer","primaryKey":"supplied"},"CaseIVIDJoin":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ItemID":{"type":"integer"},"ItemIDJoin":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ProgramID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$ProgramID.json#"},"ProgramIVID":{"type":"integer","primaryKey":"supplied"},"ProgramIVIDJoin":{"type":"string"},"ScanDocID":{"type":"integer"},"ScanDocIDFilter":{"type":"string"},"XePatientVisit":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientVisit.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitCaseManagementProgram.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XeVisitCaseManagementProgram.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
