/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.response.PatientComparePersonResponse$XePatient.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.response.PatientComparePersonResponse.json#"},"Error":{"type":"string"},"XePatient":{"$ref":"com.thrasys.xnet.erp.xmlobjects.response.PatientComparePersonResponse$XePatient.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.response.PatientComparePersonResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.response.PatientComparePersonResponse.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
