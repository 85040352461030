/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$XeScanDocSet.json#"},"Active":{"type":"boolean"},"BarCode":{"type":"string","maxLength":100},"CategoryID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":200},"DocmentTypeID":{"type":"string","maxLength":30},"ErrorDescription":{"type":"string","maxLength":500},"ExternalID":{"type":"string","maxLength":200},"FileID":{"type":"integer","primaryKey":"supplied"},"FileName":{"type":"string","maxLength":200},"FileSize":{"maximum":9999999999,"type":"integer"},"FileType":{"type":"string"},"FromDate":{"format":"date-time","nullable":true,"type":"string"},"IncomingPhone":{"type":"string","maxLength":30},"IsInError":{"type":"boolean"},"IsInline":{"type":"boolean"},"ItemID":{"type":"integer","primaryKey":"generated"},"LockDateTime":{"format":"date-time","nullable":true,"type":"string"},"MimeType":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OCRFileID":{"type":"integer"},"PageCount":{"maximum":9999,"type":"integer"},"RestrictValue":{"maximum":99999,"type":"integer"},"ScanDocID":{"type":"integer","primaryKey":"supplied"},"SourceFileID":{"type":"integer"},"SourcePhone":{"type":"string","maxLength":30},"StatusCode":{"type":"string","maxLength":30},"UploadDate":{"format":"date-time","nullable":true,"type":"string"},"UploadUser":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$XeScanDocSet.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$XeScanDocSet.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
