/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$ParentID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$XeVisitCaseManagementProgram.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$IVID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DerivedCaseIVID":{"type":"string"},"DerivedCaseVisitID":{"type":"string"},"DerivedProgram":{"type":"string"},"DerivedProgramID":{"type":"string"},"DerivedProgramIVID":{"type":"string"},"DerivedProgramVisitID":{"type":"string"},"IVID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ParentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$ParentID.json#"},"ParentIDTableIdentifier":{"type":"string"},"RestrictValue":{"maximum":99999,"type":"integer"},"Status":{"type":"string","maxLength":16},"TableIdentifier":{"type":"string"},"VisitID":{"type":"string","maxLength":60},"VisitTypeID":{"type":"string","maxLength":16},"XeVisitCaseManagementProgram":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$XeVisitCaseManagementProgram.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$IVID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$IVID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
