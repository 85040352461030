/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse$XeServiceCenter.json#"},"AssignmentCount":{"maximum":9999999999,"type":"integer"},"AssignmentLimit":{"maximum":9999999999,"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"FilterBits":{"maximum":9007199254740991,"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"OrgUnitID":{"type":"integer"},"ServiceID":{"type":"integer","primaryKey":"supplied"}},"$id":"com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse$XeServiceCenter.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse$XeServiceCenter.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
