/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientVisit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientIssue.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IssueIVID":{"type":"integer","primaryKey":"supplied"},"IssueIVIDJoin":{"type":"string"},"IssueNum":{"type":"string","maxLength":30},"IssueSubTypeID":{"type":"string","maxLength":16},"IssueTypeID":{"type":"string","maxLength":16},"ItemID":{"type":"integer"},"ItemIDJoin":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ReceivedDateTime":{"format":"date-time","nullable":true,"type":"string"},"ReceivedDateTimeOperator":{"type":"string"},"ScanDocID":{"type":"integer"},"ScanDocIDFilter":{"type":"string"},"XePatientVisit":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientVisit.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientIssue.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails$XePatientIssue.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
