/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$SexID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$XePatientAddress.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$AttributedIPID.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DateOfDeath":{"format":"local-date-time","nullable":true,"type":"string"},"DateOfDeathOff":{"type":"string"},"DoB":{"format":"local-date-time","nullable":true,"type":"string"},"DoBOff":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"integer","primaryKey":"supplied"},"IsDead":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NameTitleID":{"type":"string","maxLength":16},"NationalInsuranceNo":{"type":"string","maxLength":30},"PatientID":{"type":"string","maxLength":60},"PersonID":{"type":"string","maxLength":128},"PreferredName":{"type":"string","maxLength":100},"SecondName":{"type":"string","maxLength":100},"SexComment":{"type":"string","maxLength":500},"SexID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$SexID.json#"},"Suffix":{"type":"string","maxLength":10},"XePatientAddress":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$XePatientAddress.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$AttributedIPID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount$AttributedIPID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
