/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest$Report.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest.json#"},"FilesetNamePrefix":{"type":"string","maxLength":100},"IsSet":{"defaultValue":false,"type":"boolean"},"IsTemporary":{"defaultValue":true,"type":"boolean"},"OutputType":{"defaultValue":"pdf","type":"string","maxLength":10},"Report":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest$Report.json#"},"type":"array"},"ReturnResults":{"defaultValue":false,"type":"boolean"},"Wait":{"defaultValue":true,"type":"boolean"}},"$id":"com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
