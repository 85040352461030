/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.orderbase.XeOrderBase_UM$XeOrderBaseDispensable.json#"},"Active":{"type":"string"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NDCDescription":{"type":"string","maxLength":500},"OrderID":{"type":"integer","primaryKey":"supplied"},"RepresentativeNDC":{"type":"string","maxLength":13}},"$id":"com.thrasys.xnet.erp.xmlobjects.orderbase.XeOrderBase_UM$XeOrderBaseDispensable.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.orderbase.XeOrderBase_UM$XeOrderBaseDispensable.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
