/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$ReportContent.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$XeScanDocSet.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DocTypeID":{"type":"string","maxLength":30},"FromDate":{"format":"date-time","nullable":true,"type":"string"},"IPID":{"type":"integer"},"IsLinked":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ReportContent":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$ReportContent.json#"},"type":"array"},"RestrictValue":{"maximum":99999,"type":"integer"},"ScanDocID":{"type":"integer","primaryKey":"generated"},"UploadDate":{"format":"date-time","nullable":true,"type":"string"},"UploadUser":{"type":"integer"},"XeScanDocSet":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse$XeScanDocSet.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
