/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeDiagnosis.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeVisitDiagnosis.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":1200},"DiagnosisDescription":{"type":"string","maxLength":2000},"DiagnosisID":{"type":"string","maxLength":32},"DiagnosisTypeID":{"type":"string","maxLength":50},"IVID":{"type":"integer"},"IsChronic":{"type":"boolean"},"IsPrimaryDiagnosis":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string"},"RecordedBy":{"type":"integer"},"RecordedDate":{"format":"date-time","nullable":true,"type":"string"},"RestrictValue":{"maximum":99999,"type":"integer"},"SequenceNo":{"maximum":9999,"type":"integer"},"SeverityID":{"type":"integer"},"VisitDiagnosisID":{"type":"integer","primaryKey":"generated"},"XeDiagnosis":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeDiagnosis.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeVisitDiagnosis.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse$XeVisitDiagnosis.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
