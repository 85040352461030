/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest$OrderID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json#"},"CalendarID":{"type":"integer"},"CancelBookingOnly":{"type":"boolean"},"CancelReason":{"type":"string","maxLength":200},"CapacityCode":{"type":"string","maxLength":30},"CapacityLaneQueueID":{"type":"string","maxLength":10},"CapacityLaneQueueIDTypeSizeID":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DepartmentID":{"type":"integer"},"Description":{"type":"string","maxLength":500},"EndTime":{"format":"date-time","nullable":true,"type":"string"},"ErrorDescription":{"type":"string","maxLength":1024},"EventPartID":{"type":"integer"},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsInError":{"type":"boolean"},"IsProviderAccepted":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"OrderID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest$OrderID.json#"},"OrigIVID":{"type":"integer"},"PerformLocation":{"type":"string","maxLength":500},"PlannedHealthPlanID":{"type":"string","maxLength":32},"ProviderID":{"type":"integer"},"ResourceID":{"type":"integer"},"SchedCapacityLaneID":{"type":"integer"},"SchedEventID":{"type":"integer"},"ServiceBookingID":{"type":"integer","primaryKey":"generated"},"ServiceID":{"type":"integer"},"StartTime":{"format":"date-time","nullable":true,"type":"string"},"StatusID":{"type":"string","maxLength":16},"StatusReasonID":{"type":"string","maxLength":16},"TransitionOrder":{"type":"string"},"TravelMileage":{"maximum":99999999,"type":"integer"},"TravelTime":{"maximum":99999999,"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
