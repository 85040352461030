/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitNote.json#"},"Assessment":{"type":"string","maxLength":1000},"ContextID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"FileID":{"maximum":9007199254740991,"type":"integer"},"IVID":{"type":"integer"},"InstanceAsset":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NoteID":{"type":"integer","primaryKey":"generated"},"NoteSubTypeID":{"type":"string","maxLength":60},"NoteTypeID":{"type":"string","maxLength":60},"Notes":{"type":"string","maxLength":2147483647},"Objective":{"type":"string","maxLength":1000},"Plans":{"type":"string","maxLength":1000},"RecordedBy":{"type":"integer"},"RecordedDate":{"format":"date-time","nullable":true,"type":"string"},"Subjective":{"type":"string","maxLength":1000},"VisitAssessmentID":{"type":"integer"}},"required":["IVID"],"$id":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitNote.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XeVisitNote.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
