/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemFull.json#"},"Abbr":{"type":"string","maxLength":20},"AbbrSearchGroup":{"type":"string"},"Active":{"type":"boolean"},"CatalogID":{"type":"integer"},"Code":{"type":"string","maxLength":30},"CodeAlias":{"type":"string","maxLength":20},"CodeAliasSearchGroup":{"type":"string"},"CodeSearchGroup":{"type":"string"},"CodeStatus":{"type":"string","maxLength":1},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":1200},"DescriptionSearchGroup":{"type":"string"},"DiagnosisID":{"type":"string","primaryKey":"supplied","maxLength":32},"EffectiveDate":{"format":"date","nullable":true,"type":"string"},"EffectiveDateOperator":{"type":"string"},"ExpireDate":{"format":"date","nullable":true,"type":"string"},"ExpireDateOperator":{"type":"string"},"IsDiagnosis":{"type":"boolean"},"IsSelectable":{"type":"boolean"},"Keywords":{"type":"string","maxLength":2000},"KeywordsSearchGroup":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":800},"NameSearchGroup":{"type":"string"},"Notes":{"type":"string","maxLength":2000},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemFull.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemFull.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
