/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$NameTitleID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$Owner.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string","maxLength":100},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$NameTitleID.json#"}]}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$Owner.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary$Owner.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
