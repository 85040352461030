/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeCallLog.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeServiceBooking.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeVisitAssessment.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeWaitList.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IVID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"RestrictValue":{"maximum":99999,"type":"integer"},"XeCallLog":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeCallLog.json#"},"type":"array"},"XeServiceBooking":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeServiceBooking.json#"},"type":"array"},"XeVisitAssessment":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeVisitAssessment.json#"},"type":"array"},"XeWaitList":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeWaitList.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
