/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocUpdate.json#"},"Active":{"type":"boolean"},"ActiveDefault":{"type":"string"},"CategoryID":{"type":"string","maxLength":30},"ClassName":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Description":{"type":"string","maxLength":200},"DescriptionOperator":{"type":"string"},"DocumentTypeID":{"type":"string","maxLength":30},"ErrorDescription":{"type":"string","maxLength":500},"FileID":{"type":"integer"},"FileName":{"type":"string","maxLength":200},"FileSize":{"maximum":9999999999,"type":"integer"},"FromDate":{"format":"date-time","nullable":true,"type":"string"},"IsInError":{"type":"boolean"},"IsInErrorDefault":{"type":"string"},"ItemID":{"type":"integer","primaryKey":"generated"},"LockDateTime":{"format":"date-time","nullable":true,"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"OCRFileID":{"type":"integer"},"ParentStatusCode":{"type":"string","maxLength":30},"RestrictValue":{"maximum":99999,"type":"integer"},"ScanDocID":{"type":"integer"},"Sequence":{"maximum":9999,"type":"integer"},"StatusCode":{"type":"string","maxLength":30},"UploadDate":{"format":"date-time","nullable":true,"type":"string"},"UploadUser":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocUpdate.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocUpdate.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
