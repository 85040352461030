/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.diagnosis.AddDiagnosisRefCatalogRequest.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CatalogDiagnosisCode":{"type":"string","maxLength":30},"CatalogDiagnosisID":{"type":"integer"},"CatalogID":{"type":"integer","primaryKey":"supplied"},"CatalogTypeID":{"type":"string","maxLength":30},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DiagnosisCode":{"items":{"type":"string"},"type":"array"},"DiagnosisID":{"type":"string","maxLength":32},"IsExpSet":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"ParentID":{"type":"integer"},"RightID":{"type":"string","maxLength":60},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.diagnosis.AddDiagnosisRefCatalogRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.AddDiagnosisRefCatalogRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
