/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$EventPartID.json#"},"Active":{"defaultValue":"y","type":"string","maxLength":1},"ActiveFilter":{"type":"string"},"CalendarID":{"type":"integer","primaryKey":"supplied"},"EventPartID":{"type":"integer","primaryKey":"generated"},"IsOrganizer":{"type":"string","maxLength":1},"PartRoleID":{"type":"string","maxLength":25},"PartStatusID":{"type":"string","maxLength":25}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$EventPartID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$EventPartID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
