/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientHistory.json#"},"Active":{"type":"string"},"ActiveFilter":{"type":"string"},"AttributedIPID":{"type":"string"},"AttributedIPIDJoin":{"type":"string"},"BirthPlace":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"DoB":{"format":"date","nullable":true,"type":"string"},"EnterpriseID":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"FathersName":{"type":"string","maxLength":200},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"string"},"MaidenName":{"type":"string","maxLength":100},"MothersName":{"type":"string","maxLength":200},"NameTitleID":{"type":"string","maxLength":16},"NationalID":{"type":"string","maxLength":100},"NationalityID":{"type":"string","maxLength":16},"PatientID":{"type":"string","maxLength":100},"QuerySort":{"type":"string"},"RegistrationDate":{"format":"date","nullable":true,"type":"string"},"RegistrationTypeID":{"type":"string","maxLength":16},"SecondName":{"type":"string","maxLength":100},"SexID":{"type":"string","maxLength":16},"ThirdName":{"type":"string","maxLength":100}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientHistory.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientHistory.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
