/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.templatetokendata.TemplateTokenData$XeTokenSource.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.templatetokendata.TemplateTokenData.json#"},"FileID":{"items":{"type":"string"},"type":"array"},"IsValid":{"type":"string"},"XeTokenSource":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.templatetokendata.TemplateTokenData$XeTokenSource.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.templatetokendata.TemplateTokenData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.templatetokendata.TemplateTokenData.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
