/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWaitList.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"CategoryID":{"type":"string","maxLength":16},"CategoryIDFilter":{"type":"string"},"CompletedDateTime":{"format":"date-time","nullable":true,"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Description":{"type":"string","maxLength":1024},"IVID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"PreferredDateTime":{"format":"date-time","nullable":true,"type":"string"},"PreferredDateTimeOperator":{"type":"string"},"QuerySort":{"type":"string"},"StatusID":{"type":"string","maxLength":16},"StatusIDOperator":{"type":"string"},"SubCategoryID":{"type":"string","maxLength":30},"TaskTypeID":{"type":"string","maxLength":60},"WaitListID":{"type":"integer","primaryKey":"generated"},"WaitListSubCategoryID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWaitList.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWaitList.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
