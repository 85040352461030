/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$StaffID.json#"},"Active":{"type":"string","maxLength":1},"ActiveFilter":{"type":"string"},"Credentials":{"type":"string","maxLength":100},"EnterpriseID":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IsValid":{"type":"string","maxLength":1},"NameTitleID":{"type":"string","maxLength":16},"ResourceTypeID":{"type":"string","maxLength":50},"SecondName":{"type":"string","maxLength":100},"StaffID":{"type":"integer","primaryKey":"supplied"},"StaffIDOperator":{"type":"string"},"StaffIDResolveTo":{"type":"string"},"StaffMasterID":{"type":"string","maxLength":128},"TableIdentifier":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$StaffID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails$StaffID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
