/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse$InvoiceData.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse$PatientIssueFullResponse.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse$XeAlert.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse.json#"},"ClassName":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"InvoiceData":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse$InvoiceData.json#"},"ItemID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"PatientIssueFullResponse":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse$PatientIssueFullResponse.json#"},"RestrictValue":{"maximum":99999,"type":"integer"},"XeAlert":{"$ref":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse$XeAlert.json#"},"XeAuth":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
