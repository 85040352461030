/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest$XeRefCatModifier.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json#"},"Active":{"type":"string","maxLength":1},"ActiveXeValue":{"type":"string"},"CatItemID":{"type":"integer"},"CatalogID":{"type":"integer"},"CodeRecordID":{"type":"string","maxLength":32},"IsMultUnits":{"type":"string","maxLength":1},"IsMultUnitsXeValue":{"type":"string"},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"},"XeRefCatModifier":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest$XeRefCatModifier.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
