/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$ProviderID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$ServiceID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$StatusID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeServiceBooking.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IVID":{"type":"integer"},"IsChecked":{"type":"boolean"},"IsInError":{"type":"boolean"},"IsVisit":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ProviderID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$ProviderID.json#"},"RestrictValue":{"maximum":99999,"type":"integer"},"ServiceBookingID":{"type":"integer","primaryKey":"generated"},"ServiceID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$ServiceID.json#"},"StartTime":{"format":"date-time","nullable":true,"type":"string"},"StatusID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$StatusID.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeServiceBooking.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject$XeServiceBooking.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
