/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDocSet.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDoc.json#"},"Comments":{"type":"string","maxLength":1000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DocTypeID":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ScanDocID":{"type":"integer","primaryKey":"generated"},"ScanDocIDClearOnCopy":{"type":"string"},"XeScanDocSet":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDocSet.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDoc.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XePatientDocScanContact$XeScanDoc.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
