/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWorkflowInstanceState.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWorkflowInstance.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"HasEndedOrCleaned":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"WorkID":{"type":"integer","primaryKey":"generated"},"WorkTypeID":{"type":"string","maxLength":20},"WorkflowID":{"type":"integer"},"XeWorkflowInstanceState":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWorkflowInstanceState.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWorkflowInstance.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$XeWorkflowInstance.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
