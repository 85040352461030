/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$ProgramID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$ServiceID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$Status.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeVisitCaseManagementProgram.json#"},"CaseIVID":{"type":"integer"},"CaseIVIDJoin":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IsInError":{"type":"boolean"},"IsInErrorFilter":{"type":"string"},"Join":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ProgramID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$ProgramID.json#"},"ProgramIVID":{"type":"integer","primaryKey":"supplied"},"ProgramIVIDJoin":{"type":"string"},"ReferredIVID":{"type":"integer"},"ReferredIVIDJoin":{"type":"string"},"ServiceID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$ServiceID.json#"},"Status":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$Status.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeVisitCaseManagementProgram.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree$XeVisitCaseManagementProgram.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
