/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$StaffID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientProvider.json#"},"Active":{"type":"boolean"},"CareTypeID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IPID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ProviderID":{"type":"integer","primaryKey":"generated"},"ProviderTypeID":{"type":"string","maxLength":30},"StaffID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$StaffID.json#"},"StaffID_EnterpriseID":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientProvider.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch$XePatientProvider.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
