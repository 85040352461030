/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientAddress.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"AddressID":{"type":"string","primaryKey":"generated","maxLength":32},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"ContactInfoTypeID":{"type":"string","maxLength":16},"ContactInfoTypeIDFilter":{"type":"string"},"CountryID":{"type":"string","maxLength":16},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"Email":{"type":"string","maxLength":60},"EnterpriseID":{"type":"string"},"Fax":{"type":"string","maxLength":30},"IsPreferred":{"type":"boolean"},"IsPreferredFilter":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"Phone":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"StateProvince":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientAddress.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory$XePatientAddress.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
