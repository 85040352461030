/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$PcpID.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Credentials":{"type":"string","maxLength":100},"ExternalID":{"type":"string","maxLength":50},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NameTitleID":{"type":"string","maxLength":16},"NationalProviderID":{"type":"string","maxLength":10},"ResourceTypeID":{"type":"string","maxLength":50},"StaffID":{"type":"integer","primaryKey":"supplied"},"StaffMasterID":{"type":"string","maxLength":128},"TaxID":{"type":"string","maxLength":30}},"$id":"com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$PcpID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails$PcpID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
